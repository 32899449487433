import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/electronic-control-room',
    name: 'ElectronicControlRoom',
    component: () => import('~/views/electronicControlRoom/ElectronicControlRoom.vue'),
    meta: { title: 'Электронная диспетчерская' }
  },
  
  /* -------------------------------------------------------------------------*/
  /* Reports                                                                  */
  /* -------------------------------------------------------------------------*/

  {
    path: '/electronic-control-room/ads-analitical-report',
    name: 'AdsAnaliticalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsAnaliticalReport.vue'),
    meta: { title: 'Анализ поступивших заявок' }
  },
  {
    path: '/electronic-control-room/specific-services-report',
    name: 'SpecifiedServicesReport',
    component: () => import('~/views/electronicControlRoom/reports/SpecifiedServicesReport.vue'),
    meta: { title: 'Эксперт отчет по услугам' }
  },
  {
    path: '/electronic-control-room/request-division-report',
    name: 'RequestDivisionReport',
    component: () => import('~/views/electronicControlRoom/reports/RequestDivisionReport.vue'),
    meta: { title: 'Эксперт отчет по заявкам, подразделениям' }
  },
  {
    path: '/electronic-control-room/request-date-report',
    name: 'RequestDateReport',
    component: () => import('~/views/electronicControlRoom/reports/RequestDateReport.vue'),
    meta: { title: 'Эксперт отчет заявки по датам' }
  },
  {
    path: '/electronic-control-room/phonograms-report',
    name: 'PhonogramsReport',
    component: () => import('~/views/electronicControlRoom/reports/PhonogramsReport.vue'),
    meta: { title: 'Реестр телефонограмм' }
  },
  {
    path: '/electronic-control-room/materials-consumption-report',
    name: 'MaterialsConsumptionReport',
    component: () => import('~/views/electronicControlRoom/reports/MaterialsConsumptionReport.vue'),
    meta: { title: 'Отчет по расходованию материалов' }
  },
  {
    path: '/electronic-control-room/idents-with-electronic-bills-report',
    name: 'IdentsWithElectronicBillsReport',
    component: () => import('~/views/electronicControlRoom/reports/IdentsWithElectronicBillsReport.vue'),
    meta: { title: 'Лицевые счета, получающие квитанции в эл. виде' }
  },
  {
    path: '/electronic-control-room/idents-with-electronic-bills-changes-report',
    name: 'IdentsWithElectronicBillsChangesReport',
    component: () => import('~/views/electronicControlRoom/reports/IdentsWithElectronicBillsChangesReport.vue'),
    meta: { title: 'Лицевые счета, изменившие галку согласия получать квитанции в электронном виде' }
  },
  {
    path: '/electronic-control-room/deadline-report',
    name: 'DeadlineReport',
    component: () => import('~/views/electronicControlRoom/reports/DeadlineReport.vue'),
    meta: { title: 'Отчет по дедлайну' }
  },
  {
    path: '/electronic-control-room/calls-report',
    name: 'CallsReport',
    component: () => import('~/views/electronicControlRoom/reports/CallsReport.vue'),
    meta: { title: 'Эксперт отчет по звонкам' }
  },
  {
    path: '/electronic-control-room/ads-yammers-requests-report',
    name: 'AdsYammerRequestsReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsYammerRequestsReport.vue'),
    meta: { title: 'Жалобы и обращения' }
  },
  {
    path: '/electronic-control-room/tasks-report',
    name: 'TasksReport',
    component: () => import('~/views/electronicControlRoom/reports/TasksReport.vue'),
    meta: { title: 'Отчет по задачам' }
  },
  {
    path: '/electronic-control-room/ads-requests-phones-report',
    name: 'AdsRequestsPhonesReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsRequestsPhonesReport.vue'),
    meta: { title: 'Телефоны из заявок' }
  },
  {
    path: '/electronic-control-room/ads-requests-performing-report',
    name: 'AdsRequestsPerformingReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsRequestsPerformingReport.vue'),
    meta: { title: 'Заявки по исполнителям' }
  },
  {
    path: '/electronic-control-room/ads-pass-journal-report',
    name: 'AdsPassJournalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsPassJournalReport.vue'),
    meta: { title: 'Журнал оформленных заявок на пропуск' }
  },
  {
    path: '/electronic-control-room/ads-my-performed-journal-report',
    name: 'AdsMyPerformedJournalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsMyPerformedJournalReport.vue'),
    meta: { title: 'Мои выполненные заявки за период' }
  },
  {
    path: '/electronic-control-room/ads-journal-report',
    name: 'AdsJournalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsJournalReport.vue'),
    meta: { title: 'Журнал заявок' }
  },
  {
    path: '/electronic-control-room/ads-journal-capital-invest-report',
    name: 'AdsJournalCapitalInvestReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsJournalCapitalInvestReport.vue'),
    meta: { title: 'Журнал заявок (вариант 2)' }
  },
  {
    path: '/electronic-control-room/ads-call-statistics-report',
    name: 'AdsCallsStatisticsReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsCallsStatisticsReport.vue'),
    meta: { title: 'Статистика звонков' }
  },
  {
    path: '/electronic-control-room/ads-mark-overview-report',
    name: 'AdsMarksOverviewReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsMarksOverviewReport.vue'),
    meta: { title: 'Оценка удовлетворенности' }
  },
  {
    path: '/electronic-control-room/ads-incoming-requests-report',
    name: 'AdsIncomingRequestsReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsIncomingRequestsReport.vue'),
    meta: { title: 'Отчет по поступившим обращениям в диспетчерскую службу' }
  },
  {
    path: '/electronic-control-room/ads-missed-call-report',
    name: 'AdsMissedCallReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsMissedCallReport.vue'),
    meta: { title: 'Отчет по пропущенным звонкам' }
  },
  {
    path: '/electronic-control-room/last-users-activity-report',
    name: 'LastUsersActivityReport',
    component: () => import('~/views/electronicControlRoom/reports/LastUsersActivityReport.vue'),
    meta: { title: 'Отчет по последней активности пользователей сотрудников' }
  },
  // {
  //   path: '/electronic-control-room/ads-employee-geolocation-report',
  //   name: 'AdsEmployeeGeolocation',
  //   component: () => import('~/views/electronicControlRoom/reports/AdsEmployeeGeolocation.vue'),
  //   meta: { title: 'Отчет по геолокации сотрудников' }
  // },

  /* -------------------------------------------------------------------------*/
  /* Lists                                                                    */
  /* -------------------------------------------------------------------------*/

  
  // Addresses
  {
    path: '/electronic-control-room/accounts',
    name: 'ElectronicControlRoomAccounts',
    component: () => import('~/views/electronicControlRoom/lists/addresses/ElectronicControlRoomAccounts.vue'),
    meta: { title: '' }
  },

  {
    path: '/electronic-control-room/houses',
    name: 'ElectronicControlRoomHouses',
    component: () => import('~/views/electronicControlRoom/lists/addresses/ElectronicControlRoomHouses.vue'),
    meta: { title: 'Лицевые счета' }
  },

  {
    path: '/electronic-control-room/groups-of-districts',
    name: 'ElectronicControlRoomGroupsOfDistricts',
    component: () => import('~/views/electronicControlRoom/lists/addresses/ElectronicControlRoomGroupsOfDistricts.vue'),
    meta: { title: 'Группы районов' }
  },

  {
    path: '/electronic-control-room/home-groups',
    name: 'ElectronicControlRoomHomeGroups',
    component: () => import('~/views/electronicControlRoom/lists/addresses/ElectronicControlRoomHomeGroups.vue'),
    meta: { title: 'Районы' }
  },

  // Requests

  {
    path: '/electronic-control-room/requests/request-source-types',
    name: 'RequestSourceTypes',
    component: () => import('~/views/electronicControlRoom/lists/requests/RequestSourceTypes.vue'),
    meta: { title: 'Типы источника заявок' }
  },

  {
    path: '/electronic-control-room/requests/request-types',
    name: 'RequestTypes',
    component: () => import('~/views/electronicControlRoom/lists/requests/RequestTypes.vue'),
    meta: { title: 'Типы заявок' }
  },

  {
    path: '/electronic-control-room/requests/malfunction-types',
    name: 'MalfunctionTypes',
    component: () => import('~/views/electronicControlRoom/lists/requests/MalfunctionTypes.vue'),
    meta: { title: 'Виды неисправностей' }
  },

  {
    path: '/electronic-control-room/requests/detailed-malfunction-types',
    name: 'DetailedMalfunctionTypes',
    component: () => import('~/views/electronicControlRoom/lists/requests/DetailedMalfunctionTypes.vue'),
    meta: { title: 'Подробные виды неисправностей' }
  },

  {
    path: '/electronic-control-room/requests/divisions',
    name: 'Divisions',
    component: () => import('~/views/electronicControlRoom/lists/requests/Divisions.vue'),
    meta: { title: 'Подразделения' }
  },

  {
    path: '/electronic-control-room/requests/request-pools',
    name: 'RequestPools',
    component: () => import('~/views/electronicControlRoom/lists/requests/RequestPools.vue'),
    meta: { title: 'Пулы заявок' }
  },

  {
    path: '/electronic-control-room/requests/email-gates',
    name: 'EmailGates',
    component: () => import('~/views/electronicControlRoom/lists/requests/EmailGates.vue'),
    meta: { title: 'Email-источников заявок' }
  },

  {
    path: '/electronic-control-room/requests/email-gate-filters',
    name: 'EmailGateFilters',
    component: () => import('~/views/electronicControlRoom/lists/requests/EmailGateFilters.vue'),
    meta: { title: 'Email правила фильтрации' }
  },

  {
    path: '/electronic-control-room/requests/to-shareholder-telegram-subjects',
    name: 'ToShareholderTelegramSubjects',
    component: () => import('~/views/electronicControlRoom/lists/requests/ToShareholderTelegramSubjects.vue'),
    meta: { title: 'Темы обращений к директору в Телеграм' }
  },

  {
    path: '/electronic-control-room/requests/emergency-request-channels',
    name: 'EmergencyRequestChannels',
    component: () => import('~/views/electronicControlRoom/lists/requests/EmergencyRequestChannels.vue'),
    meta: { title: 'Телеграм группы для отправки аварийных заявок' }
  },

  {
    path: '/electronic-control-room/requests/help-dispatcher-general',
    name: 'HelpDispatcherGeneral',
    component: () => import('~/views/electronicControlRoom/lists/requests/HelpDispatcherGeneral.vue'),
    meta: { title: 'Информация в помощь диспетчеру' }
  },

  {
    path: '/electronic-control-room/requests/employment-calendar',
    name: 'EmploymentCalendar',
    component: () => import('~/views/electronicControlRoom/lists/requests/EmploymentCalendar.vue'),
    meta: { title: 'График работы сотрудников' }
  },

  {
    path: '/electronic-control-room/requests/auto-move-settings',
    name: 'ElectronicControlRoomAutoMoveSettings',
    component: () => import('~/views/electronicControlRoom/lists/requests/ElectronicControlRoomAutoMoveSettings.vue'),
    meta: { title: 'Настройки для автоперевода заявок' }
  },

  {
    path: '/electronic-control-room/requests/auto-subscription-for-overdue',
    name: 'ECRAutoSubscriptionForOverdue',
    component: () => import('~/views/electronicControlRoom/lists/requests/AutoSubscriptionForOverdue.vue'),
    meta: { title: 'Настройки автоподключения к просроченным заявкам' }
  },

  {
    path: '/electronic-control-room/requests/auto-move-low-mark',
    name: 'ECRAutoMoveLowMark',
    component: () => import('~/views/electronicControlRoom/lists/requests/AutoMoveLowMark.vue'),
    meta: { title: 'Настройки автоперевода заявок с низкой оценкой' }
  },

  {
    path: '/electronic-control-room/requests/request-additional-fields',
    name: 'RequestAdditionalFields',
    component: () => import('~/views/electronicControlRoom/lists/requests/RequestAdditionalFields.vue'),
    meta: { title: 'Дополнительные поля заявок' }
  },

  {
    path: '/electronic-control-room/requests/task-types',
    name: 'TaskTypes',
    component: () => import('~/views/electronicControlRoom/lists/requests/TaskTypes.vue'),
    meta: { title: 'Типы задач' }
  },

  // Calls

  {
    path: '/electronic-control-room/calls/calls',
    name: 'Calls',
    component: () => import('~/views/electronicControlRoom/lists/calls/Calls.vue'),
    meta: { title: 'Журнал звонков' }
  },

  {
    path: '/electronic-control-room/calls/missed-calls',
    name: 'MissedCalls',
    component: () => import('~/views/electronicControlRoom/lists/calls/MissedCalls.vue'),
    meta: { title: 'Пропущенные звонки' }
  },

  {
    path: '/electronic-control-room/calls/voip-users-common-list',
    name: 'VoipUsersCommonList',
    component: () => import('~/views/electronicControlRoom/lists/calls/VoipUsersCommonList.vue'),
    meta: { title: 'Общий список пользователей телефонии' }
  },

  {
    path: '/electronic-control-room/calls/support-acceptor-phones',
    name: 'SupportAcceptorPhones',
    component: () => import('~/views/electronicControlRoom/lists/calls/SupportAcceptorPhones.vue'),
    meta: { title: 'Параметры входящих номеров по событиям от АТС' }
  },

  // objects-of-control
  {
    path: '/electronic-control-room/control-objects',
    name: 'ControlObjects',
    component: () => import('~/views/electronicControlRoom/lists/controlObjects/ControlObjects.vue'),
    meta: { title: 'Объекты контроля' }
  },

  // Settings

  {
    path: '/electronic-control-room/settings/phones-comments',
    name: 'PhonesComments',
    component: () => import('~/views/electronicControlRoom/lists/settings/PhonesComments.vue'),
    meta: { title: 'Комментарии к телефонным номерам' }
  },

  {
    path: '/electronic-control-room/settings/daily-work-schedule',
    name: 'DailyWorkSchedule',
    component: () => import('~/views/electronicControlRoom/lists/settings/DailyWorkSchedule.vue'),
    meta: { title: 'Ежедневное расписание работы' }
  },

  // Users

  {
    path: '/electronic-control-room/user-management/users',
    name: 'Users',
    component: () => import('~/views/electronicControlRoom/lists/users/Users.vue'),
    meta: { title: 'Пользователи' }
  },

  {
    path: '/electronic-control-room/user-management/house-groups',
    name: 'HouseGroups',
    component: () => import('~/views/electronicControlRoom/lists/users/HouseGroups.vue'),
    meta: { title: 'Группы домов' }
  },

  // Passes

  {
    path: '/electronic-control-room/passes/passes',
    name: 'Passes',
    component: () => import('~/views/electronicControlRoom/lists/passes/Passes.vue'),
    meta: { title: 'Пропуска' }
  },

  {
    path: '/electronic-control-room/passes/pass-categories',
    name: 'PassCategories',
    component: () => import('~/views/electronicControlRoom/lists/passes/PassCategories.vue'),
    meta: { title: 'Категории пропусков' }
  },

  {
    path: '/electronic-control-room/passes/vehicle-number-templates',
    name: 'VehicleNumberTemplates',
    component: () => import('~/views/electronicControlRoom/lists/passes/VehicleNumberTemplates.vue'),
    meta: { title: 'Шаблоны номеров автотранспорта' }
  },

  {
    path: '/electronic-control-room/passes/vehicle-marks',
    name: 'VehicleMarks',
    component: () => import('~/views/electronicControlRoom/lists/passes/VehicleMarks.vue'),
    meta: { title: 'Марки автомобилей' }
  },

  {
    path: '/electronic-control-room/passes/black-list-car-numbers',
    name: 'BlackListCarNumbers',
    component: () => import('~/views/electronicControlRoom/lists/passes/BlackListCarNumbers.vue'),
    meta: { title: 'Черный список автотранспорта' }
  },

  {
    path: '/electronic-control-room/passes/black-list-idents',
    name: 'BlackListIdents',
    component: () => import('~/views/electronicControlRoom/lists/passes/BlackListIdents.vue'),
    meta: { title: 'Черный список лицевых счетов' }
  },

  // Document Types

  {
    path: '/electronic-control-room/document-types',
    name: 'DocumentTypes',
    component: () => import('~/views/electronicControlRoom/lists/documentTypes/DocumentTypes.vue'),
    meta: { title: 'Типы документов' }
  },

  // Templates

  {
    path: '/electronic-control-room/support-pattern-texts',
    name: 'SupportPatternTexts',
    component: () => import('~/views/electronicControlRoom/lists/templates/SupportPatternTexts.vue'),
    meta: { title: 'Шаблонные ответы диспетчера' }
  },

  {
    path: '/electronic-control-room/support-notification-templates',
    name: 'SupportNotificationTemplates',
    component: () => import('~/views/electronicControlRoom/lists/templates/SupportNotificationTemplates.vue'),
    meta: { title: 'Шаблоны уведомлений' }
  },

  {
    path: '/electronic-control-room/ads-doc-templates',
    name: 'AdsDocTemplates',
    component: () => import('~/views/electronicControlRoom/lists/templates/AdsDocTemplates.vue'),
    meta: { title: 'Шаблоны документов' }
  },

   // Phonograms
   {
    path: '/electronic-control-room/phonograms/phonogram-types',
    name: 'PhonogramTypes',
    component: () => import('~/views/electronicControlRoom/lists/phonograms/PhonogramTypes.vue'),
    meta: { title: 'Типы телефонограмм' }
  },

  // Material Types
  {
    path: '/electronic-control-room/materials/materials-types',
    name: 'MaterialTypes',
    component: () => import('~/views/electronicControlRoom/lists/materials/MaterialTypes.vue'),
    meta: { title: 'Типы материалов' }
  },

  // material journal

  {
    path: '/electronic-control-room/materials/material-journal',
    name: 'MaterialJournal',
    component: () => import('~/views/electronicControlRoom/lists/materials/MaterialJournal.vue'),
    meta: { title: 'Приход материалов' }
  },

    // gis ads auto-task pack
    {
      path: '/electronic-control-room/integration/gis-ads-auto-task-pack',
      name: 'GisAdsAutoTaskPack',
      component: () => import('~/views/electronicControlRoom/lists/integration/GisAdsAutoTaskPack.vue'),
      meta: { title: 'Периодические задачи обмена с ГИС ЖКХ и ЕДС МО' }
    },
  
  /* -------------------------------------------------------------------------*/
  /* Imports                                                                  */
  /* -------------------------------------------------------------------------*/

  {
    path: '/electronic-control-room/black-list-idents/vehicle-marks/import',
    name: 'ImportVehicleMarks',
    component: () => import('~/views/electronicControlRoom/file-analysis/ImportVehicleMarks.vue'),
    meta: { title: 'Загрузка марок автомобилей' }
  },
  {
    path: '/electronic-control-room/black-list-idents/black-list-car-number/import',
    name: 'ImportBlacklistCarNumbers',
    component: () => import('~/views/electronicControlRoom/file-analysis/ImportBlacklistCarNumbers.vue'),
    meta: { title: 'Загрузка черного списка автотранспорта' }
  },
  {
    path: '/electronic-control-room/black-list-idents/black-list-ident/import',
    name: 'ImportBlackListIdents',
    component: () => import('~/views/electronicControlRoom/file-analysis/ImportBlackListIdents.vue'),
    meta: { title: 'Загрузка черного списка лицевых счетов' }
  },
  // Long process
  {
    path: '/electronic-control-room/account-from-tko-template-processor',
    name: 'AccountFromTkoTemplateProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountFromTkoTemplateProcessor.vue'),
    meta: { title: 'Обновить ИЖКУ по результатам обработки шаблона ЛС ТКО' }
  },
  {
    path: '/electronic-control-room/accounts-csv-file-processor',
    name: 'AccountsCsvFileProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountsCsvFileProcessor.vue'),
    meta: { title: 'Выгрузить ЛС/ЕЛС в CSV' }
  },
  {
    path: '/electronic-control-room/accounts-excel-file-processor',
    name: 'AccountsExcelFileProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/AccountsExcelFileProcessor.vue'),
    meta: { title: 'Выгрузить ЛС/ЕЛС в Excel' }
  },
  {
    path: '/electronic-control-room/create-cr-accounts-processor',
    name: 'CreateCRAccountsProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/CreateCRAccountsProcessor.vue'),
    meta: { title: 'Создать лицевые счета для кап.ремонта' }
  },
  {
    path: '/electronic-control-room/export-houses-to-excel-processor',
    name: 'ExportHousesToExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesToExcelProcessor.vue'),
    meta: { title: 'Выгрузить доп. информацию по домам (XLSX)' }
  },
  {
    path: '/electronic-control-room/export-houses-without-fias-processor',
    name: 'ExportHousesWithoutFiasProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ExportHousesWithoutFiasProcessor.vue'),
    meta: { title: 'Выгрузить дома без ФИАС в CSV файл' }
  },
  {
    path: '/electronic-control-room/fill-template-tko-accounts-processor',
    name: 'FillTemplateTKOAccountsProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/FillTemplateTKOAccountsProcessor.vue'),
    meta: { title: 'Выгрузить ЛС ТКО' }
  },
  {
    path: '/electronic-control-room/house-creation-master-processor',
    name: 'HouseCreationMasterProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseCreationMasterProcessor.vue'),
    meta: { title: 'Мастер создания дома' }
  },
  {
    path: '/electronic-control-room/house-fiases-processor',
    name: 'HouseFiasesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseFiasesProcessor.vue'),
    meta: { title: 'Загрузить ФИАСы' }
  },
  {
    path: '/electronic-control-room/houses-for-tko-contract-processor',
    name: 'HousesForTkoContractProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HousesForTkoContractProcessor.vue'),
    meta: { title: 'Заполнить шаблон по добавлению ОЖФ в договор ТКО(XLSX)' }
  },
  {
    path: '/electronic-control-room/house-type-by-premise-type-processor',
    name: 'HouseTypeByPremiseTypeProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/HouseTypeByPremiseTypeProcessor.vue'),
    meta: { title: 'Изменение типа домов по кол-ву помещений' }
  },
  {
    path: '/electronic-control-room/import-houses-from-excel-processor',
    name: 'ImportHousesFromExcelProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/ImportHousesFromExcelProcessor.vue'),
    meta: { title: 'Загрузить доп. информацию по домам (XLSX)' }
  },
  {
    path: '/electronic-control-room/premises-not-linked-to-account-remove-processor',
    name: 'PremisesNotLinkedToAccountRemoveProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/PremisesNotLinkedToAccountRemoveProcessor.vue'),
    meta: { title: 'Удалить помещения, не привязанные к ЛС' }
  },
  {
    path: '/electronic-control-room/update-houses-addresses-processor',
    name: 'UpdateHousesAddressesProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/UpdateHousesAddressesProcessor.vue'),
    meta: { title: 'Обновление адресов домов' }
  },
  {
    path: '/electronic-control-room/fill-daily-work-schedule-processor',
    name: 'FillDailyWorkScheduleProcessor',
    component: () => import('~/views/electronicControlRoom/long-process/FillDailyWorkScheduleProcessor.vue'),
    meta: { title: 'Заполнение графика работы организации' }
  },
]